import { Box, Card, CardActions, CardContent, CircularProgress, IconButton, Typography } from '@mui/material';
import WorkItemIterationsProvider from './measures/WorkItemIterationsProvider';
import RefreshIcon from '@mui/icons-material/Refresh';
import BurndownChart from './BurndownChart';
import WorkItemCardHeader from './WorkItemCardHeader';
import { useWorkItem } from './hooks/WorkItemHooks';

type BurndownCardProps = {
  id: number,
};

export default function BurndownCard({id} : BurndownCardProps) {
  const { workItem, allNodes, leafNodes, isLoading, reload } = useWorkItem(id);

  return (
    <WorkItemIterationsProvider allNodes={allNodes}>
      <Card sx={{ minWidth: 1200, maxWidth: 1500, backgroundColor: "white" /*WorkItemColor.BackgroundColor[workItem.type]*/ }}>
        <WorkItemCardHeader workItem={workItem} allNodes={allNodes} leafNodes={leafNodes} />
        <CardContent>
          <Typography variant="h6">Burndown</Typography><br/>
          {isLoading ? <>Loading ...</> : <BurndownChart workItem={workItem} />}
        </CardContent>
        <CardActions disableSpacing>
          {
            !isLoading ?
              <IconButton onClick={reload} aria-label="refresh"><RefreshIcon /></IconButton>
            : <Box marginLeft={1} marginRight={1}><CircularProgress size={24} /></Box>
          }
          <Typography>
            {workItem.loadedDateTime.toLocaleDateString()} {workItem.loadedDateTime.toLocaleTimeString()}
          </Typography>
        </CardActions>
      </Card>
    </WorkItemIterationsProvider>
  );
}