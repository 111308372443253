import { createContext } from 'react';

const CommittedMeasuresContext = createContext({} as {
  committedItemCount: number,
  uncommittedItemCount: number,
  committedDays: number,
  uncommittedDays: number,
  committedItemPercentage: number
});

export default CommittedMeasuresContext;