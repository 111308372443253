import { useContext } from "react";
import TaskedMeasures from "../measures/TaskedMeasures";
import TaskedMeasuresContext from "../measures/TaskedMeasuresContext";
import WorkItemMetric from "./WorkItemMetric";

type TaskedMetricProps = {
  state?: string;
  measures?: TaskedMeasures;
};

export default function TaskedMetric({ state, measures } : TaskedMetricProps) {
  const { taskedTotal } = useContext(TaskedMeasuresContext);
  if (!measures) measures = taskedTotal;
  if (!state) state = 'TASKED';

  return (
    <WorkItemMetric
      percentage={parseInt(Number(measures.taskedItemPercentage).toFixed())}
      state={state}
      detail={<>{measures.untaskedItemCount} items at {measures.untaskedDays}d not tasked<br/>
        {measures.taskedItemCount} items at {measures.taskedDays}d tasked</>}
      sx={{marginBottom: 2}}
      />
  );
}