import { Buffer } from 'buffer';
import { useMemo } from 'react';
import * as NodeApi from "azure-devops-node-api";
import { IRequestHandler } from 'azure-devops-node-api/interfaces/common/VsoBaseInterfaces';
import DevOpsContext from './DevOpsContext';

type DevOpsProviderProps = {
  authHandler?: IRequestHandler;
  children?: React.ReactChild | React.ReactChild[];
};

export default function DevOpsProvider({authHandler, children}: DevOpsProviderProps) {
    // Dependencies need this to be defined in the global namespace
    if (typeof global.Buffer === 'undefined')
      global.Buffer = Buffer;

    const orgUrl = "https://dev.azure.com/microsoft";
    const api = useMemo<NodeApi.WebApi|undefined>(() => (authHandler ? new NodeApi.WebApi(orgUrl, authHandler) : undefined), [authHandler]);

    return (
      <DevOpsContext.Provider value={[api]}>
        {children}
      </DevOpsContext.Provider>
    );
}