import React, { useState } from 'react';
import BacklogProvider from './BacklogProvider';
import WorkItemViewList from './WorkItemViewList';

import * as CoreInterfaces from 'azure-devops-node-api/interfaces/CoreInterfaces';
import BacklogContext from './BacklogContext';
import Box from '@mui/material/Box';
// import WorkItemView from './WorkItemView';

import {
  useParams
} from "react-router-dom";

function Backlog() {
  const { project, team } = useParams();
  const [teamContext] = useState({
    project: project ?? "OS",
    team: team ?? "Enterprise Intelligence"
  } as CoreInterfaces.TeamContext);

  return (
    <BacklogProvider teamContext={teamContext}>
      <BacklogContext.Consumer>
        {
          ({ backlog }) =>
            <Box marginTop={5}>
              <WorkItemViewList ids={backlog.root.childIds} />
              {/* We could show the backlog as an item and allow cards of objectives,
                  however this is currently computationally expensive.
              */}
              {/* <WorkItemView id={backlog.root.id} /> */}
            </Box>
        }
      </BacklogContext.Consumer>
    </BacklogProvider>
  );
}

export default Backlog;
