import { useCallback, useState } from 'react';
import WorkItemState from '../model/WorkItemState';
import * as WorkItemColor from "./WorkItemColor";
import CompletedMetricGroup from './metrics/CompletedMetricGroup';
import WorkItemChildCounts from './WorkItemChildCounts';
import { Box, Card, CardActions, CardContent, CircularProgress, Collapse, Dialog, IconButton, styled, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkItemIterationsProvider from './measures/WorkItemIterationsProvider';
import WorkItemCardHeader from './WorkItemCardHeader';
import { useWorkItem } from './hooks/WorkItemHooks';
import ReportsMetric from './metrics/ReportsMetric';
import ReportIcon from '@mui/icons-material/ListAlt';
import BurndownIcon from '@mui/icons-material/BarChart';
import ReportCard from './ReportCard';
import BurndownCard from './BurndownCard';
import ScopedMetricGroup from './metrics/ScopedMetricGroup';
import TaskedMetricGroup from './metrics/TaskedMetricGroup';

const ExpandMore = styled<any>((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type WorkItemCardProps = {
  id: number,
  onFocus: (event: React.SyntheticEvent<Element, Event>, id: number) => void
};

export default function WorkItemCard({ id, onFocus }: WorkItemCardProps) {
  const { workItem, allNodes, leafNodes, isLoading, reload } = useWorkItem(id);
  const [expanded, setExpanded] = useState(false);
  const [ openReport, setOpenReport] = useState(false);
  const [ openBurndown, setOpenBurndown] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleEdit = useCallback(() => {
    let link = workItem.serviceDocument?._links?.['html']?.['href'];
    if (!link) link = "https://microsoft.visualstudio.com/OS/_workitems/edit/" + workItem.id;
    window.open(link);
  }, [workItem]);

  const handleOpenBurndown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenBurndown(true);
  }, []);

  const handleCloseBurndown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenBurndown(false);
  }, []);

  const handleOpenReport = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenReport(true);
  }, []);

  const handleCloseReport = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenReport(false);
  }, []);

  if (!workItem || workItem.state === WorkItemState.Cut) {
    return <></>
  }

  return (
    <WorkItemIterationsProvider allNodes={allNodes}>
      <Card sx={{ width: 450, backgroundColor: WorkItemColor.BackgroundColor[workItem.type] }}>
        <WorkItemCardHeader workItem={workItem} allNodes={allNodes} leafNodes={leafNodes} onFocus={onFocus} />
        <CardContent>
          {
            !isLoading &&
            <>
              <CompletedMetricGroup workItem={workItem} leafNodes={leafNodes} />
              <ScopedMetricGroup workItem={workItem} leafNodes={leafNodes} />
              <TaskedMetricGroup workItem={workItem} leafNodes={leafNodes} />
              <ReportsMetric workItem={workItem} />
              {/* <GoalsStatusMetric workItem={workItem} />
              <HighlightsStatusMetric workItem={workItem} />
              <LowlightsStatusMetric workItem={workItem} />
              <PlansStatusMetric workItem={workItem} /> */}
            </>
          }
          <WorkItemChildCounts workItem={workItem} allNodes={allNodes} />
        </CardContent>
        <CardActions disableSpacing>
          <IconButton onClick={handleEdit} aria-label="edit"><EditIcon /></IconButton>
          <IconButton onClick={handleOpenReport}><ReportIcon /></IconButton>
          <IconButton onClick={handleOpenBurndown}><BurndownIcon /></IconButton>
          {
            !isLoading ?
              <IconButton onClick={reload} aria-label="refresh"><RefreshIcon /></IconButton>
              : <Box marginLeft={1} marginRight={1}><CircularProgress size={24} /></Box>
          }
          <Typography>
            {workItem.loadedDateTime.toLocaleDateString()} {workItem.loadedDateTime.toLocaleTimeString()}
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ backgroundColor: "white", margin: 2 }}>
            <div dangerouslySetInnerHTML={{ __html: workItem.serviceDocument?.fields?.["System.Description"] ?? "No description" }} />
          </CardContent>
        </Collapse>
      </Card>
      <Dialog onClose={handleCloseReport} open={openReport} scroll='body' maxWidth={false} fullWidth={false}>
        <ReportCard id={workItem.id} />
      </Dialog>
      <Dialog onClose={handleCloseBurndown} open={openBurndown} scroll='body' maxWidth={false} fullWidth={false}>
        <BurndownCard id={workItem.id} />
      </Dialog>
    </WorkItemIterationsProvider>
  );
}