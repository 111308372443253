import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { shadeColor } from "../../Util";
import * as WorkItemColor from "../WorkItemColor";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkItemType from "../../model/WorkItemType";

type TextMetricProps = {
  color?: string;
  workItemType: WorkItemType;
  summary: JSX.Element;
  defaultExpanded?: boolean;
  children?: React.ReactChild | React.ReactChild[];
  expanded?: boolean;
  onChange?: ((event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void) | undefined;
};

export default function TextMetric({defaultExpanded, expanded, onChange, workItemType, summary, children, color = "primary"}: TextMetricProps) {
  return (
    <Accordion defaultExpanded={defaultExpanded} expanded={expanded} onChange={onChange} sx={{backgroundColor: shadeColor(WorkItemColor.BackgroundColor[workItemType], 0.075)}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography display="flex" flex={1} color={color} alignItems={"center"} justifyContent="center">
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}