import { LinearProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import WorkItem from "../model/WorkItem";
import WorkItemMap from "../model/WorkItemMap";
import WorkItemType from "../model/WorkItemType";
import { exponentialMovingAverage, sleep } from "../Util";
import BacklogContext from "./BacklogContext";
import useEffectAsync from "./hooks/UseEffectAsync";

const options = {
};

type BurndownChartProps = {
  workItem: WorkItem;
};

function getIntervals(start: Date, end: Date, givenDay: number = 5 /* Friday */) {
  let currentDate = new Date(start);
  let intervals: Date[] = [];

  // Find every Friday between the ranges
  while (currentDate <= end) {
    // console.log(currentDate.valueOf(),start.valueOf(),end);
    if (currentDate.valueOf() === start.valueOf()) {
      intervals.push(new Date(start));
    } else if (currentDate.valueOf() === end.valueOf() && start.valueOf() !== end.valueOf()) {
      intervals.push(new Date(end));
    } else if (currentDate.getDay() === givenDay && start.valueOf() !== end.valueOf()) {
      intervals.push(new Date(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }
  // Get last 12 weeks
  let weekRange = Math.min(12, intervals.length);
  let weekIntervals = intervals.splice(intervals.length - weekRange, weekRange);

  // Get remaining last 9 months
  let monthRange = Math.min(9 * 4, intervals.length);
  let monthIntervals = intervals.splice(intervals.length - monthRange, monthRange);
  monthIntervals = monthIntervals.filter((value, index) => (index % 4) === 0);

  // Get remaining last 5 years
  let yearRange = Math.min(52 * 5, intervals.length);
  let yearIntervals = intervals.splice(intervals.length - yearRange, yearRange);
  yearIntervals = yearIntervals.filter((value, index) => (index % 52) === 0);

  return [...yearIntervals, ...monthIntervals, ...weekIntervals];
}

// function getEstimateIntervals(start: Date, end: Date, intercept: number) {
//   const diffTime = Math.abs(end.valueOf() - start.valueOf());
//   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//   const diffIntervals = Math.min(0.25 * (diffDays / 7), intercept); // Estimate at 25% max

//   let intervals: Date[] = []
//   let currentDate = new Date(end);
//   for (let i = 0; i < diffIntervals; ++i) {
//     intervals.push(new Date(currentDate));
//     currentDate.setDate(currentDate.getDate() + 7);
//   }

//   return intervals;
// }

function getPeakIndex(values: [Date, number][]) {
  if (!values.length) return undefined;

  // Find max value (peak work)
  let peakIndex = values.reduce(([max, maxIndex], value, index) => {
    if (value[1] > max) {
      max = value[1];
      maxIndex = index;
    }
    return [max, maxIndex];
  }, [0,0])[1];
  return peakIndex;
}

function getPeak(values: [Date, number][]) {
  if (!values.length) return undefined;
  const peakIndex = getPeakIndex(values);
  if (peakIndex === undefined) return undefined;
  const peak = values[peakIndex];
  return { index: peakIndex, date: peak[0], value: peak[1] };
}

// Return velocity as effort per day
function getVelocityAsEffortPerDay(values: [Date, number][]) {
  let peak = getPeak(values);

  // Need another value to be able to compute slope
  if (peak === undefined || peak.index === values.length) {
    return 0;
  }
  let peakValue: [Date, number] = [peak.date, peak.value];

  // Calculate as a moving average of effort from day to day.
  // This is likely more accurate than just a linear estimation.
  // It smooths out short-term fluctuations and highlights longer
  // trends.

  // Get the remaining intervals
  let remaining = values.slice(peak.index + 1, values.length);

  // Get the velocities
  let velocities: number[] = [];
  remaining.reduce((previous, value, index) => {
    const diffTime = Math.abs(value[0].valueOf() - previous[0].valueOf());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let effortPerDay =  (previous[1] - value[1])/diffDays;
    velocities.push(effortPerDay);
    return value;
  }, peakValue);

  // Get the average of the velocities
  let averages = exponentialMovingAverage(velocities);
  if (!averages.length) return 0;
  return averages.reduce((prev, curr) => prev + curr, 0) / averages.length;

  // This alternative linear estimation doesn't take into account periods of inactivity
  // in the same way. It may miss trends.
  //
  // let lastValue = values[values.length - 1];
  // const diffTime = Math.abs(lastValue[0].valueOf() - peak.date.valueOf());
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // let velocity = (peakValue[1] - lastValue[1])/diffDays;
  // return velocity;
}

// How many days from end of current data to remaining = 0 and intercepts x axis
function getIntercept(daysRemaining: number, velocity: number) {
  // Get x intercept: y = mx + b
  if (velocity > 0.1) {
    let m = -velocity;
    let b = daysRemaining;
    let x = (-b) / m;
    return x;
  }

  return undefined;
}

function getInterceptAsDate(end: [Date, number], velocity: number) {
  let intercept = getIntercept(end[1], velocity);
  if (!intercept) return undefined;
  let interceptDate = new Date(end[0]);
  interceptDate.setDate(interceptDate.getDate() + intercept);
  return interceptDate;
}

function getEstimateValues(values: [Date, number][]) {
  const estimateValues: [Date, number][] = [];

  let velocity = getVelocityAsEffortPerDay(values);
  if (velocity <= 0) return { estimateValues, undefined };

  let startDate = values[0][0];
  let end = values[values.length-1];
  let endDate = end[0];

  const diffTime = Math.abs(endDate.valueOf() - startDate.valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  let estimateDays = diffDays;
  let estimateDate = new Date(endDate);
  estimateDate.setDate(estimateDate.getDate() + estimateDays);

  let interceptDate = getInterceptAsDate(values[values.length-1], velocity);
  if (!interceptDate) return { estimateValues, undefined };

  estimateDate = estimateDate > interceptDate ? interceptDate : estimateDate;

  estimateValues.push(end);
  let intervals = getIntervals(endDate, estimateDate);
  let estimateValue = end[1];
  for (let i = 0; i < intervals.length; ++i) {
    let prevInterval = i === 0 ? endDate : intervals[i-1];
    let currentInterval = intervals[i];
    const diffTime = Math.abs(currentInterval.valueOf() - prevInterval.valueOf());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    estimateValue -= velocity * diffDays;
    estimateValues.push([currentInterval, estimateValue]);
  }

  return { estimateValues, interceptDate };
}

export default function BurndownChart({ workItem }: BurndownChartProps) {
  // export function BurndownChart2({workItem}: BurndownChartProps) {
  const [data, setData] = useState<any[]>([]);
  const { loadWorkItemMap } = useContext(BacklogContext);
  const [intervals, setIntervals] = useState<Date[]>([]);
  const [maps, setMaps] = useState(new Map<Date, WorkItemMap>());
  const [values, setValues] = useState<[Date, number][]>([]);
  const [loadingInterval, setLoadingInterval] = useState(new Date());
  const [loadingIntervalIndex, setLoadingIntervalIndex] = useState(1);
  const [velocity, setVelocity] = useState<number|undefined>();
  const [projectCompletionDate, setProjectCompletionDate] = useState<Date|undefined>();

  useEffect(() => {
    if (workItem.type === WorkItemType.Backlog) return;
    let iterationDates = workItem.getScheduledIterationsAsDateRange();
    if (!iterationDates) iterationDates = { start: new Date(), end: new Date() }
    if (iterationDates.start > new Date()) iterationDates.start = new Date();
    if (iterationDates.end > new Date()) iterationDates.end = new Date();
    let intervals = getIntervals(iterationDates.start, iterationDates.end);
    setIntervals(intervals);
  }, [workItem]);

  useEffectAsync(async signal => {
    if (!intervals.length) return;

    let maps = new Map<Date, WorkItemMap>();

    let index = 0;
    if (signal.aborted) return;

    setLoadingIntervalIndex(0);
    for (const interval of intervals) {
      if (signal.aborted) return;
      setLoadingIntervalIndex(++index);
      setLoadingInterval(interval);
      await sleep();
      if (signal.aborted) return;
      if (!workItem) return;
      let map = await loadWorkItemMap(workItem.id, Number.MAX_VALUE, signal, true, true, interval);
      if (signal.aborted) return;
      if (!map) {
        console.error("Unable to load work item: " + workItem.id);
        return;
      }
      maps.set(interval, map);
    }

    setMaps(maps);
    setLoadingIntervalIndex(0);
  }, [workItem, loadWorkItemMap, intervals]);

  useEffect(() => {
    if (!intervals.length) return;

    let values: [Date, number][] = [];

    for (const interval of intervals) {
      let map = maps.get(interval);
      let value = 0;
      if (map) {
        let item = map.get(workItem.id);
        value = item?.getCompletedMetric().remainingDays ?? 0;
      }
      values.push([interval, value]);
    }
    setValues(values);
    // Only run if maps changes. Do not include interval or work item
    // as that could cause a re-render before maps is fully populated.
    // And we need to avoid re-renders because the Google charts component
    // doesn't deal well with unloading.
    // https://github.com/rakannimer/react-google-charts/issues/268
  }, [maps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!values.length) return;

    const { estimateValues, interceptDate } = getEstimateValues(values);
    setProjectCompletionDate(interceptDate);
    let velocity = getVelocityAsEffortPerDay(values);
    setVelocity(velocity);

    const data = [
      ["Date", "Remaining Days", "Projected Days"],
      ...values.map(item => [item[0], item[1], 0]),
      ...estimateValues.map(item => [item[0], 0, item[1]])
    ];
    // console.log(data);
    setData(data);
  }, [values]);

  if (loadingIntervalIndex) {
    return (
      <Box>
        <Typography>Loading interval #{loadingIntervalIndex} of {intervals.length} for {loadingInterval.toLocaleDateString()} ...<br /><br /></Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <Typography display="inline" fontWeight="bold">Velocity:</Typography> {velocity && (velocity * 7 > 0.1) ? (velocity * 7).toFixed(1) + " d/wk" : "None"}<br/>
        <Typography display="inline" fontWeight="bold">Projected Completion Date:</Typography> {projectCompletionDate?.toLocaleDateString() ?? "None"}
      </Box>
      <Chart
        chartType="AreaChart"
        data={data}
        options={options}
        loader={<>Loading chart resources ...</>}
        errorElement={<>Error loading chart</>}
        height={400}
      />
    </Box>
  );
}

