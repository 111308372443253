import { Box, Card, CardActions, CardContent, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import WorkItemIterationsProvider from './measures/WorkItemIterationsProvider';
import ReportSchedule from './ReportSchedule';
import RefreshIcon from '@mui/icons-material/Refresh';
import GoalsStatusMetric from './metrics/GoalsStatusMetric';
import HighlightsStatusMetric from './metrics/HighlightsStatusMetric';
import LowlightsStatusMetric from './metrics/LowlightsStatusMetric';
import PlansStatusMetric from './metrics/PlansStatusMetric';
import WorkItemCardHeader from './WorkItemCardHeader';
import { useWorkItem } from './hooks/WorkItemHooks';
import { useCallback, useEffect, useState } from 'react';
import { InsightsReport, InsightsPhase } from '../model/InsightsDocument';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ReportPhases from './ReportPhases';

type ReportCardProps = {
  id: number,
};

export default function ReportCard({ id }: ReportCardProps) {
  const { workItem, allNodes, leafNodes, isLoading, reload } = useWorkItem(id);
  const [reports, setReports] = useState<InsightsReport[]>([]);
  const [phases, setPhases] = useState<InsightsPhase[]>([]);
  const [activeReportIndex, setActiveReportIndex] = useState<string | undefined>("0");
  const [scheduleType, setScheduleType] = useState<string>("timeline");

  const handleChange = (event: SelectChangeEvent) => {
    setActiveReportIndex(event.target.value);
  };

  const handleScheduleTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: string,
  ) => {
    if (newType) setScheduleType(newType);
  };

  useEffect(() => {
    const doc = workItem.insightsDocument;
    let reports = doc?.reports ?? [];
    setReports(reports);

    if (!reports.length) return;

    let maxDate = new Date(0);
    let activeReportIndex = reports.reduce((previous, current, index) => {
      if (current.reportDateTime && new Date(current.reportDateTime) > maxDate) {
        maxDate = new Date(current.reportDateTime);
        return index;
      }
      return previous;
    }, 0);
    setActiveReportIndex(activeReportIndex.toString());
  }, [workItem]);

  useEffect(() => {
    const doc = workItem.insightsDocument;
    let phases = doc?.phases ?? [];
    setPhases(phases);
  }, [workItem]);

  const ScheduleChart = useCallback(() => {
    if (isLoading) return <>Loading ...</>

    return (
      <>
        {
          phases.length > 0 &&
          <ToggleButtonGroup
            value={scheduleType}
            exclusive
            onChange={handleScheduleTypeChange}
            aria-label="child view type"
            sx={{ marginLeft: 'auto', marginRight: 2, marginBottom: 2 }}
          >
            <ToggleButton value="phases" aria-label="phases">
              Phases
            </ToggleButton>
            <ToggleButton value="timeline" aria-label="timeline">
              Timeline
            </ToggleButton>
          </ToggleButtonGroup>
        }
        {scheduleType === "timeline" ? <ReportSchedule workItem={workItem} /> : <ReportPhases phases={phases} />}
      </>);
  }, [isLoading, phases, scheduleType, workItem]);

  return (
    <WorkItemIterationsProvider allNodes={allNodes}>
      <Card sx={{ minWidth: 1200, maxWidth: 1500, backgroundColor: "white" /*WorkItemColor.BackgroundColor[workItem.type]*/ }}>
        <WorkItemCardHeader workItem={workItem} allNodes={allNodes} leafNodes={leafNodes} />
        <CardContent>
          {
            reports.length > 0 &&
            <>
              <FormControl>
                <InputLabel id="report-label">Report</InputLabel>
                <Select
                  labelId="report-label"
                  id="report"
                  value={activeReportIndex}
                  label="Report"
                  onChange={handleChange}
                >
                  {
                    reports.map((report, index) => <MenuItem key={index} value={index.toString()}>{report.title ? report.title : report.reportDateTime ? new Date(report.reportDateTime).toLocaleDateString() : "Report " + (index + 1)}</MenuItem>)
                  }
                </Select>
              </FormControl>
              <br /><br />
              <GoalsStatusMetric workItem={workItem} mode="text" reportIndex={parseInt(activeReportIndex ?? "0")} />
              <HighlightsStatusMetric workItem={workItem} mode="text" reportIndex={parseInt(activeReportIndex ?? "0")} />
              <LowlightsStatusMetric workItem={workItem} mode="text" reportIndex={parseInt(activeReportIndex ?? "0")} />
              <PlansStatusMetric workItem={workItem} mode="text" reportIndex={parseInt(activeReportIndex ?? "0")} />
            </>
          }
          <Typography variant="h6">Schedule</Typography><br />
          <ScheduleChart />
        </CardContent>
        <CardActions disableSpacing>
          {
            !isLoading ?
              <IconButton onClick={reload} aria-label="refresh"><RefreshIcon /></IconButton>
              : <Box marginLeft={1} marginRight={1}><CircularProgress size={24} /></Box>
          }
          <Typography>
            {workItem.loadedDateTime.toLocaleDateString()} {workItem.loadedDateTime.toLocaleTimeString()}
          </Typography>
        </CardActions>
      </Card>
    </WorkItemIterationsProvider>
  );
}