import { useContext } from "react";
import CommittedMeasuresContext from "../measures/CommittedMeasuresContext";
import WorkItemMetric from "./WorkItemMetric";

export default function CommittedMetric() {
  const { committedItemCount, uncommittedItemCount, committedDays, uncommittedDays, committedItemPercentage } = useContext(CommittedMeasuresContext);

  return (
    <WorkItemMetric
      percentage={parseInt(Number(committedItemPercentage).toFixed())}
      state='COMMITTED'
      detail={<>{uncommittedItemCount} items at {uncommittedDays}d not committed<br/>
        {committedItemCount} items at {committedDays}d committed</>}
      sx={{marginBottom: 2}}
      />
  );
}