import { useContext } from "react";
import ScheduledMeasuresContext from "../measures/ScheduledMeasuresContext";
import WorkItemMetric from "./WorkItemMetric";

export default function ScheduledMetric() {
  const { scheduledItemCount, unscheduledItemCount, scheduledDays, unscheduledDays, scheduledItemPercentage } = useContext(ScheduledMeasuresContext);

  return (
    <WorkItemMetric
      percentage={parseInt(Number(scheduledItemPercentage).toFixed())}
      state='SCHEDULED'
      detail={<>{unscheduledItemCount} items at {unscheduledDays}d not scheduled<br/>
        {scheduledItemCount} items at {scheduledDays}d scheduled</>}
      sx={{marginBottom: 2}}
      />
  );
}