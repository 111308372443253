import EstimatedMeasuresContext from './EstimatedMeasuresContext';
import WorkItem from '../../model/WorkItem';
import { useEffect, useState } from 'react';
import WorkItemState from '../../model/WorkItemState';

type EstimatedMeasuresProviderProps = {
  leafNodes: WorkItem[],
  children?: React.ReactChild | React.ReactChild[];
};

export default function EstimatedMeasuresProvider({ leafNodes, children }: EstimatedMeasuresProviderProps) {
  const [estimatedItemCount, setEstimatedItemCount] = useState(0);
  const [unestimatedItemCount, setUnestimatedItemCount] = useState(0);
  const [estimatedDays, setEstimatedDays] = useState(0);
  const [estimatedItemPercentage, setEstimatedItemPercentage] = useState(0);

  // Calculate estimatedCount and estimatedDays
  useEffect(() => {
    // If remainingDays is available, we could use that as an estimation,
    // but over time that would shrink the total scope of work as the
    // item completes. Instead, items should be properly estimated.
    // Therefore, rely on originalEstimate rather than bestKnownEstimate.
    const estimatedItemCount = leafNodes.reduce((total: number, item: WorkItem) => {
      if (item.state === WorkItemState.Completed)
        return total + 1; // Count completed work as estimated whether it was or not

      return total + (item.originalEstimate ? 1 : 0);
    }, 0);
    setEstimatedItemCount(estimatedItemCount);
    setUnestimatedItemCount(leafNodes.length - estimatedItemCount);

    const estimatedDays = leafNodes.reduce((total: number, item: WorkItem) => {
      return total + (item.originalEstimate ?? 0);
    }, 0);
    setEstimatedDays(estimatedDays);

    setEstimatedItemPercentage(leafNodes.length ? (100 * estimatedItemCount / leafNodes.length) : 0);
  }, [leafNodes]);

  return (
    <EstimatedMeasuresContext.Provider value={{ estimatedItemCount, unestimatedItemCount, estimatedDays, estimatedItemPercentage }}>
      {children}
    </EstimatedMeasuresContext.Provider>
  );
}