import WorkItem from "../../model/WorkItem";
import * as WorkItemColor from "../WorkItemColor";
import { shadeColor } from "../../Util";

import CommittedMeasuresProvider from '../measures/CommittedMeasuresProvider';
import EstimatedMeasuresProvider from '../measures/EstimatedMeasuresProvider';
import ScheduledMeasuresProvider from "../measures/ScheduledMeasuresProvider";

import ScopedMetric from './ScopedMetric';
import CommittedMetric from './CommittedMetric';
import EstimatedMetric from './EstimatedMetric';
import ScheduledMetric from "./ScheduledMetric";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

type ScopedMetricGroupProps = {
  workItem: WorkItem,
  leafNodes: WorkItem[],
};

export default function ScopedMetricGroup({ workItem, leafNodes}: ScopedMetricGroupProps) {
  return (
    <EstimatedMeasuresProvider leafNodes={leafNodes}>
      <CommittedMeasuresProvider leafNodes={leafNodes}>
        <ScheduledMeasuresProvider leafNodes={leafNodes}>
          <Accordion sx={{backgroundColor: shadeColor(WorkItemColor.BackgroundColor[workItem.type], 0.075)}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header" sx={{flex: 1}}>
              <Box width="100%">
                <ScopedMetric />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{marginBottom: 3}}/>
              <EstimatedMetric />
              <ScheduledMetric />
              <CommittedMetric />
            </AccordionDetails>
          </Accordion>
        </ScheduledMeasuresProvider>
      </CommittedMeasuresProvider>
    </EstimatedMeasuresProvider>
  );
}