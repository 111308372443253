import { useCallback, useContext, useEffect, useRef, useState } from "react";
import WorkItem from "../../model/WorkItem";
import WorkItemType from "../../model/WorkItemType";
import { sleep } from "../../Util";
import BacklogContext from "../BacklogContext";
import useEffectAsync from "./UseEffectAsync";

export function useWorkItemNodes(workItem: WorkItem) {
  const [allNodes, setAllNodes] = useState<WorkItem[]>([]);
  const [leafNodes, setLeafNodes] = useState<WorkItem[]>([]);

  useEffect(() => {
    setAllNodes(workItem.allNodes);
    setLeafNodes(workItem.leafNodes);
  }, [workItem]);

  return { allNodes, leafNodes };
}

export function useWorkItem(id: number) {
  const { /*backlog,*/ loadWorkItem } = useContext(BacklogContext);

  const [workItem, setWorkItem] = useState<WorkItem>(new WorkItem(id, "Loading ...", WorkItemType.Backlog));
  const { allNodes, leafNodes } = useWorkItemNodes(workItem);

  const [isLoading, setIsLoading] = useState(true);
  const [loadCounter, setLoadCounter] = useState(0);
  const lastLoadCounter = useRef(0);

  const reload = useCallback(() => {
    setLoadCounter(prevState => prevState + 1);
  }, []);

  // Load data
  useEffectAsync(async signal => {
    await sleep();
    if (signal.aborted) return;
    const refreshNeeded = lastLoadCounter.current !== loadCounter;
    setIsLoading(true);
    let workItem = await loadWorkItem(id, Number.MAX_VALUE, signal, !refreshNeeded);
    if (signal.aborted) return;
    if (workItem) setWorkItem(workItem);
    setIsLoading(false);
    lastLoadCounter.current = loadCounter;
  }, [id, loadWorkItem, loadCounter]);

  // Assign work item
  // useEffect(() => {
  //   const workItem = backlog.findById(id);
  //   if (workItem) {
  //     setWorkItem(workItem);
  //   }
  // }, [id, backlog]);

  return { workItem, allNodes, leafNodes, isLoading, reload }
}