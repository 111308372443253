import CommittedContext from './CommittedMeasuresContext';
import WorkItem from '../../model/WorkItem';
import { useEffect, useState } from 'react';
import WorkItemState from '../../model/WorkItemState';

type CommittedMeasuresProviderProps = {
  leafNodes: WorkItem[],
  children?: React.ReactChild | React.ReactChild[];
};

export default function CommittedMeasuresProvider({ leafNodes, children }: CommittedMeasuresProviderProps) {
  const [committedItemCount, setCommittedItemCount] = useState(0);
  const [uncommittedItemCount, setUncommittedItemCount] = useState(0);
  const [committedDays, setCommittedDays] = useState(0);
  const [uncommittedDays, setUncommittedDays] = useState(0);
  const [committedItemPercentage, setCommittedItemPercentage] = useState(0);

  // Calculate state
  useEffect(() => {
    let count = leafNodes.reduce((total: number, item: WorkItem) => {
      return total + (item.isCommitted ? 1 : 0);
    }, 0);
    setCommittedItemCount(count);
    setUncommittedItemCount(leafNodes.length - count);

    let percentCommitted = leafNodes.length ? (100 * count / leafNodes.length) : 0;
    setCommittedItemPercentage(percentCommitted);

    let days = leafNodes.reduce((total: number, item: WorkItem) => {
      if (item.state === WorkItemState.Proposed)
        return total;

      return total + item.bestKnownEstimate;
    }, 0);
    setCommittedDays(days);

    days = leafNodes.reduce((total: number, item: WorkItem) => {
      if (item.state !== WorkItemState.Proposed)
        return total;

      return total + item.bestKnownEstimate;
    }, 0);
    setUncommittedDays(days);
  }, [leafNodes]);

  return (
    <CommittedContext.Provider value={{ committedItemCount, uncommittedItemCount, committedDays, uncommittedDays, committedItemPercentage }}>
      {children}
    </CommittedContext.Provider>
  );
}