import { useCallback, useEffect, useState } from "react";
import WorkItem from "../../model/WorkItem";
import PlanIcon from '@mui/icons-material/NextPlan';
import TextMetric from "./TextMetric";
import { InsightsPlan } from "../../model/InsightsDocument";
import Typography from "@mui/material/Typography";

type PlansStatusMetricProps = {
  workItem: WorkItem;
  reportIndex: number;
  mode?: 'card' | 'text';
};

export default function PlansStatusMetric({ workItem, reportIndex, mode = 'card' }: PlansStatusMetricProps) {
  const [plans, setPlans] = useState<InsightsPlan[]>([]);
  const [planCount, setPlanCount] = useState(0);

  useEffect(() => {
    const doc = workItem.insightsDocument;
    if (!doc?.reports || !doc.reports.length) return;
    let report = doc.reports[reportIndex];
    if (report.plans) {
      setPlans(report.plans);
      setPlanCount(report.plans.length);
    } else {
      setPlans([]);
      setPlanCount(0);
    }
  }, [workItem, reportIndex]);

  const PlansText = useCallback(() => {
    return(
      <ul>
      {plans?.map(({ item }, index) =>
        <li key={index}>{item}</li>
      )}
      </ul>
    )
  }, [plans]);

  if (!planCount)
    return (<></>);

  if (mode === 'text') {
    return (
      <>
        <Typography variant="h6">Plans</Typography>
        <PlansText />
      </>
    );
  }

  return (
    <TextMetric workItemType={workItem.type} color="purple" summary={
      <>
        <PlanIcon sx={{ marginRight: 1 }} />
        {planCount} {planCount === 1 ? "Plan" : "Plans"}
      </>
    }>
      <PlansText />
    </TextMetric>
  );
}