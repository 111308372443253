import WorkItem from "../../model/WorkItem";
import Divider from "@mui/material/Divider";
import GoalsStatusMetric from "./GoalsStatusMetric";
import HighlightsStatusMetric from "./HighlightsStatusMetric";
import LowlightsStatusMetric from "./LowlightsStatusMetric";
import PlansStatusMetric from "./PlansStatusMetric";
import { InsightsReport } from "../../model/InsightsDocument";
import { useCallback, useEffect, useState } from "react";
import TextMetric from "./TextMetric";
import ReportIcon from "@mui/icons-material/ListAlt";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import grey from "@mui/material/colors/grey";
import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";

type ReportsMetricProps = {
  workItem: WorkItem
};

export default function ReportsMetric({ workItem }: ReportsMetricProps) {
  const [expanded, setExpanded] = useState(false);
  const [reports, setReports] = useState<InsightsReport[]>([]);
  const [activeReportIndex, setActiveReportIndex] = useState<string | undefined>("0");
  const [reportSchemaExpanded, setReportSchemaExpanded] = useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setActiveReportIndex(event.target.value);
  };

  const expandReportSchema = useCallback(() => {
    setReportSchemaExpanded(prevState => !prevState);
  }, []);

  useEffect(() => {
    const doc = workItem.insightsDocument;
    let reports = doc?.reports ?? [];
    setReports(reports);

    // const sortedReports = [...reports];
    // sortedReports.sort((a,b) => {
    //   if (!a.reportDateTime && !b.reportDateTime) {
    //     const aIndex = reports.indexOf(a);
    //     const bIndex = reports.indexOf(b);
    //     if (aIndex < bIndex) return -1;
    //     if (aIndex === bIndex) return 0;
    //     if (aIndex > bIndex) return 1;
    //   }
    //   if (a.reportDateTime && !b.reportDateTime) return -1;
    //   if (!a.reportDateTime && b.reportDateTime) return 1;
    //   if (a.reportDateTime && b.reportDateTime) {
    //     if (new Date(a.reportDateTime) < new Date(b.reportDateTime)) return -1;
    //     if (new Date(a.reportDateTime) > new Date(b.reportDateTime)) return 1;
    //   }
    //   return 0;
    // });

    if (!reports.length) return;

    let maxDate = new Date(0);
    let activeReportIndex = reports.reduce((previous, current, index) => {
      if (current.reportDateTime && new Date(current.reportDateTime) > maxDate) {
        maxDate = new Date(current.reportDateTime);
        return index;
      }
      return previous;
    }, 0);
    setActiveReportIndex(activeReportIndex.toString());
    // setExpanded(true);
  }, [workItem]);

  const handleExpandedChange = useCallback((event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  }, []);

  return (
    <TextMetric color={grey[700]} expanded={expanded} onChange={handleExpandedChange} workItemType={workItem.type} summary={
      <>
        <ReportIcon sx={{ marginRight: 1 }} />
        {reports.length} {reports.length === 1 ? "Report" : "Reports"}
      </>
    }>
      {
        reports.length ?
          <>
            <FormControl fullWidth>
              <InputLabel id="report-label">Report</InputLabel>
              <Select
                labelId="report-label"
                id="report"
                value={activeReportIndex}
                label="Report"
                onChange={handleChange}
              >
                {
                  reports.map((report, index) => <MenuItem key={index} value={index.toString()}>{report.title ? report.title : report.reportDateTime ? new Date(report.reportDateTime).toLocaleDateString() : "Report " + (index + 1)}</MenuItem>)
                }
              </Select>
            </FormControl>
            <Divider sx={{ marginBottom: 3 }} />
            <GoalsStatusMetric workItem={workItem} reportIndex={parseInt(activeReportIndex ?? "0")} />
            <HighlightsStatusMetric workItem={workItem} reportIndex={parseInt(activeReportIndex ?? "0")} />
            <LowlightsStatusMetric workItem={workItem} reportIndex={parseInt(activeReportIndex ?? "0")} />
            <PlansStatusMetric workItem={workItem} reportIndex={parseInt(activeReportIndex ?? "0")} />
          </>
          :
          <>
            <Typography>
              No reports available. Add a report by appending the <Link href={"#workitem-schema-" + workItem.id.toString()} itemRef={"workitem-schema-" + workItem.id.toString()} onClick={expandReportSchema}>report schema</Link> to the <em>Description</em> field of the work item.
            </Typography>
            <Collapse in={reportSchemaExpanded} timeout="auto" unmountOnExit>
              <br/>The schema is YAML and whitespace matters.
              <pre><code>
                ---<br />
                schema: insights/v1<br />
                reports:<br />
                &nbsp;&nbsp;- reportDateTime: "2011-03-11"<br />
                &nbsp;&nbsp;&nbsp;&nbsp;goals:<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Goal 1 ..."<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Goal 2 ..."<br />
                &nbsp;&nbsp;&nbsp;&nbsp;highlights:<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Highlight 1 ..."<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Highlight 2 ..."<br />
                &nbsp;&nbsp;&nbsp;&nbsp;lowlights:<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Lowlight 1 ..."<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Lowlight 2 ..."<br />
                &nbsp;&nbsp;&nbsp;&nbsp;plans:<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Plan 1 ..."<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- item: "Plan 2 ..."<br />
              </code></pre>
            </Collapse>
          </>
      }
    </TextMetric>
  );
}