import React, { useCallback, useEffect, useState } from "react";
import WorkItem from "../../model/WorkItem";
import GoalIcon from '@mui/icons-material/TrackChanges';
import TextMetric from "./TextMetric";
import { InsightsGoalGroup } from "../../model/InsightsDocument";
import { Typography } from "@mui/material";

type GoalsStatusMetricProps = {
  workItem: WorkItem;
  reportIndex: number;
  mode?: 'card' | 'text';
};

export default function GoalsStatusMetric({ workItem, reportIndex, mode = 'card' }: GoalsStatusMetricProps) {
  const [goalGroups, setGoalGroups] = useState<InsightsGoalGroup[]>([]);
  const [goalCount, setGoalCount] = useState(0);
  const [hasGoalGroups, setHasGoalGroups] = useState(false);

  useEffect(() => {
    const doc = workItem.insightsDocument;
    if (!doc?.reports || !doc.reports.length) return;
    let report = doc.reports[reportIndex];

    let goalGroups: InsightsGoalGroup[] = [];
    let goalCount = 0;
    if (report.goals && report.goals.length) {
      goalGroups.push({ group: "Goals", items: report.goals });
      goalCount += report.goals.length;
    }

    if (report.goalGroups) {
      goalGroups.push(...report.goalGroups);

      for (const goalGroup of report.goalGroups) {
        goalCount += goalGroup.items ? goalGroup.items.length : 0;
      }

      setHasGoalGroups(true);
    }

    setGoalCount(goalCount);
    setGoalGroups(goalGroups);
  }, [workItem, reportIndex]);

  const GoalsText = useCallback(() => {
    return(
      <>
      {goalGroups?.map(({ group, items }, index) =>
        <React.Fragment key={index}>
          {(hasGoalGroups || mode === 'text') && <Typography variant="h6">{group ? group : "Goals"}</Typography>}
          <ul>
            {items?.map(({ item }, index) =>
              <li key={index}>{item}</li>
            )}
          </ul>
        </React.Fragment>
      )}
      </>
    )
  }, [goalGroups, hasGoalGroups, mode]);

  if (!goalCount)
    return (<></>);

  if (mode === 'text') {
    return <GoalsText />
  }

  return (
    <TextMetric workItemType={workItem.type} summary={
      <>
        <GoalIcon sx={{ marginRight: 1 }} />
        {goalCount} {goalCount === 1 ? "Goal" : "Goals"}
      </>
    }>
      <GoalsText/>
    </TextMetric>
  );
}