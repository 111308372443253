import TaskedMeasuresContext from './TaskedMeasuresContext';
import WorkItem from '../../model/WorkItem';
import { useEffect, useState } from 'react';
import TaskedMeasures from './TaskedMeasures';
import IterationRange from '../../model/IterationRange';

type TaskedMeasuresProviderProps = {
  workItem: WorkItem,
  leafNodes: WorkItem[],
  children?: React.ReactChild | React.ReactChild[];
};

export default function TaskedMeasuresProvider({ workItem, leafNodes, children }: TaskedMeasuresProviderProps) {
  const [taskedTotal, setTaskedTotal] = useState(new TaskedMeasures());
  const [taskedEarlier, setTaskedEarlier] = useState(new TaskedMeasures());
  const [taskedCurrent, setTaskedCurrent] = useState(new TaskedMeasures());
  const [taskedNext, setTaskedNext] = useState(new TaskedMeasures());
  const [taskedLater, setTaskedLater] = useState(new TaskedMeasures());

  useEffect(() => {
    setTaskedTotal(workItem.getTaskedMetric(leafNodes, IterationRange.All));
    setTaskedEarlier(workItem.getTaskedMetric(leafNodes, IterationRange.Earlier));
    setTaskedCurrent(workItem.getTaskedMetric(leafNodes, IterationRange.Current));
    setTaskedNext(workItem.getTaskedMetric(leafNodes, IterationRange.Next));
    setTaskedLater(workItem.getTaskedMetric(leafNodes, IterationRange.Later));
  }, [workItem, leafNodes]);

  return (
    <TaskedMeasuresContext.Provider value={{ taskedTotal, taskedEarlier, taskedCurrent, taskedNext, taskedLater }}>
      {children}
    </TaskedMeasuresContext.Provider>
  );
}