import { createContext } from 'react';

const ScheduledMeasuresContext = createContext({} as {
  scheduledItemCount: number,
  unscheduledItemCount: number,
  scheduledDays: number,
  unscheduledDays: number,
  scheduledItemPercentage: number
});

export default ScheduledMeasuresContext;