import blue from "@mui/material/colors/blue";
import green from "@mui/material/colors/green";
import grey from "@mui/material/colors/grey";
import red from "@mui/material/colors/red";
import yellow from "@mui/material/colors/yellow";
import { useEffect, useState } from "react";
import WorkItem from "../../model/WorkItem";
import WorkItemState from "../../model/WorkItemState";
import WorkItemMetric from "./WorkItemMetric";

type CompletedMetricProps = {
  workItem: WorkItem;
  leafNodes: WorkItem[];
};

function getTextColor(state: WorkItemState, percentage: number) {
  switch(state)
  {
    case WorkItemState.AtRisk:
      return yellow[900];
    case WorkItemState.Committed:
    case WorkItemState.Completed:
    case WorkItemState.OnTrack:
      return green[700];
    case WorkItemState.Cut:
      return grey[700];
    case WorkItemState.Missed:
      return red[500];
    case WorkItemState.Proposed:
      return grey[700];
    case WorkItemState.Started:
    default:
      return blue[500];
  }
}

function getBarColor(state: WorkItemState, percentage: number) {
  switch(state)
  {
    case WorkItemState.AtRisk:
      return 'warning';
    case WorkItemState.Committed:
    case WorkItemState.Completed:
    case WorkItemState.OnTrack:
      return 'success';
    case WorkItemState.Cut:
    case WorkItemState.Proposed:
        return 'info';
    case WorkItemState.Missed:
      return 'error';
    case WorkItemState.Started:
    default:
      return 'primary';
  }
}

export default function CompletedMetric({ workItem, leafNodes }: CompletedMetricProps) {
  const [remainingDays, setRemainingDays] = useState(0);
  const [originalEstimate, setOriginalEstimate] = useState(0);
  const [completedPercentage, setCompletedPercentage] = useState(0);

  useEffect(() => {
    const { remainingDays, originalEstimate, completedPercentage } = workItem.getCompletedMetric(leafNodes);
    setRemainingDays(remainingDays);
    setOriginalEstimate(originalEstimate);
    setCompletedPercentage(completedPercentage);
  }, [workItem, leafNodes]);

  return (
    <WorkItemMetric
      percentage={parseInt(Number(workItem.state === WorkItemState.Completed ? 100 : completedPercentage).toFixed())}
      state='COMPLETED'
      detail={<><br/>{remainingDays}d of {originalEstimate ?? remainingDays}d remaining</>}
      textColor={getTextColor(workItem.state, completedPercentage)}
      barColor={getBarColor(workItem.state, completedPercentage)}
      />
  );
}