import { useContext } from "react";
import EstimatedMeasuresContext from "../measures/EstimatedMeasuresContext";
import WorkItemMetric from "./WorkItemMetric";

export default function EstimatedMetric() {
  const { estimatedItemCount, unestimatedItemCount, estimatedDays, estimatedItemPercentage } = useContext(EstimatedMeasuresContext);

  return (
    <WorkItemMetric
      percentage={parseInt(Number(estimatedItemPercentage).toFixed())}
      state='ESTIMATED'
      detail={<>{unestimatedItemCount} items not estimated<br/>
        {estimatedItemCount} items at {estimatedDays}d estimated</>}
      sx={{marginBottom: 1}}/>
  );
}