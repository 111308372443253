import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { InsightsPhase } from "../model/InsightsDocument";
import WorkItem from "../model/WorkItem";

const options = {
  timeline: {
    groupByRowLabel: true,
    // width: 900
    // height: 1000
    // height: "800px"
  }
};

const columns = [
  { type: "string", id: "Name" },
  // { type: "string", id: "Phase" },
  { type: "date", id: "Start" },
  { type: "date", id: "End" },
];

type ReportPhasesProps = {
  phases: InsightsPhase[];
};

function wordWrap(str: string, maxWidth: number) {
  let newLineStr = "\n"; let res = '';
  while (str.length > maxWidth) {
      let found = false;
      // Inserts new line at first whitespace of the line
      for (let i = maxWidth - 1; i >= 0; i--) {
          if (testWhitespace(str.charAt(i))) {
              res = res + [str.slice(0, i), newLineStr].join('');
              str = str.slice(i + 1);
              found = true;
              break;
          }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
          res += [str.slice(0, maxWidth), newLineStr].join('');
          str = str.slice(maxWidth);
      }

  }

  return res + str;
}

function testWhitespace(x: string) {
  var white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
};

export default function ReportPhases({phases}: ReportPhasesProps) {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    let rows = phases.map((phase, index) => {
      // If missing a range then skip
      if (!phase.startDateTime && !phase.startIteration) return [null,null,null];
      if (!phase.endDateTime && !phase.endIteration) return [null,null,null];

      let start: Date | undefined;
      let end: Date | undefined;

      // If date time, use it
      if (phase.startDateTime) start = new Date(phase.startDateTime);
      if (phase.endDateTime) end = new Date(phase.endDateTime);

      // If iteration, use it
      if (!start && phase.startIteration) start = WorkItem.getIterationAsDateRange(parseInt(phase.startIteration))?.start;
      if (!end && phase.endIteration) end = WorkItem.getIterationAsDateRange(parseInt(phase.endIteration))?.end;

      return [phase.title ?? "Phase " + index, start, end];
    });

    let data = [columns, ...rows];

    setData(data);
  }, [phases]);

  if (!data.length) {
    return (
      <Box>
        <Typography>Loading ...<br/><br/></Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Chart
      chartType="Timeline"
      data={data}
      options={options}
      height={60 * data.length}
    />
  );
}
