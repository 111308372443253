import ScheduledContext from './ScheduledMeasuresContext';
import WorkItem from '../../model/WorkItem';
import { useEffect, useState } from 'react';

type ScheduledMeasuresProviderProps = {
  leafNodes: WorkItem[],
  children?: React.ReactChild | React.ReactChild[];
};

export default function ScheduledMeasuresProvider({ leafNodes, children }: ScheduledMeasuresProviderProps) {
  const [scheduledItemCount, setScheduledItemCount] = useState(0);
  const [unscheduledItemCount, setUnscheduledItemCount] = useState(0);
  const [scheduledDays, setScheduledDays] = useState(0);
  const [unscheduledDays, setUnscheduledDays] = useState(0);
  const [scheduledItemPercentage, setScheduledItemPercentage] = useState(0);

  // Calculate state
  useEffect(() => {
    let count = leafNodes.reduce((total: number, item: WorkItem) => {
      return total + (item.isScheduled ? 1 : 0);
    }, 0);
    setScheduledItemCount(count);
    setUnscheduledItemCount(leafNodes.length - count);

    let percentScheduled = leafNodes.length ? (100 * count / leafNodes.length) : 0;
    setScheduledItemPercentage(percentScheduled);

    let days = leafNodes.reduce((total: number, item: WorkItem) => {
      if (!item.isScheduled)
        return total;

      return total + item.bestKnownEstimate;
    }, 0);
    setScheduledDays(days);

    days = leafNodes.reduce((total: number, item: WorkItem) => {
      if (item.isScheduled)
        return total;

      return total + item.bestKnownEstimate;
    }, 0);
    setUnscheduledDays(days);
  }, [leafNodes]);

  return (
    <ScheduledContext.Provider value={{ scheduledItemCount, unscheduledItemCount, scheduledDays, unscheduledDays, scheduledItemPercentage }}>
      {children}
    </ScheduledContext.Provider>
  );
}