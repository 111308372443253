import { Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import BacklogContext from './BacklogContext';
import WorkItemCard from './WorkItemCard';

type WorkItemCardsProps = {
  ids: number[],
  onFocus: (event: React.SyntheticEvent<Element, Event>, id: number) => void
};

export default function WorkItemCards({ids, onFocus} : WorkItemCardsProps) {
  const { backlog } = useContext(BacklogContext);
  const [sortedIds, setSortedIds] = useState(ids);

  useEffect(() => {
    let sortedIds = ids.slice(0);
    sortedIds.sort((firstId, secondId) => {
      let firstItem = backlog.findFirst(item => item.id === firstId);
      let secondItem = backlog.findFirst(item => item.id === secondId);
      if (!firstItem && !secondItem)
        return 0;

      if (!firstItem || !firstItem.iteration)
        return 1;

      if (!secondItem || !secondItem.iteration)
        return -1;

      if (firstItem.iteration === secondItem.iteration)
        return firstItem.id < secondItem.id ? -1 : 1;

      return firstItem.iterationAsNumber < secondItem.iterationAsNumber ? -1 : 1;
    });
    setSortedIds(sortedIds);
  }, [ids, backlog]);

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={4}>
      {
        sortedIds.length ?
          sortedIds.map(id => <Grid item key={id}><WorkItemCard key={id} id={id} onFocus={onFocus} /></Grid>)
          : <Typography align='center'>No items</Typography>
      }
    </Grid>
  );
}