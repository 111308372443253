import { useContext, useEffect, useState } from "react";
import EstimatedMeasuresContext from "../measures/EstimatedMeasuresContext";
import CommittedMeasuresContext from "../measures/CommittedMeasuresContext";
import ScheduledMeasuresContext from "../measures/ScheduledMeasuresContext";
import WorkItemMetric from "./WorkItemMetric";

function ScopeRisk(props: any) {
  return (
    <>
    {props.scopedPercentage >= 90 ? "Highly Scoped" :
      props.scopedPercentage >= 70 ? "Moderately Scoped" :
        props.scopedPercentage >= 50 ? "Minimally Scoped" :
          "Marginally Scoped"}
    </>
  );
}

export default function ScopedMetric() {
  const { estimatedItemPercentage } = useContext(EstimatedMeasuresContext);
  const { committedItemPercentage } = useContext(CommittedMeasuresContext);
  const { scheduledItemPercentage } = useContext(ScheduledMeasuresContext);
  const [scopedPercentage, setScopedPercentage] = useState(0);

  useEffect(() => {
    setScopedPercentage((estimatedItemPercentage + committedItemPercentage + scheduledItemPercentage)/3);
  }, [estimatedItemPercentage, committedItemPercentage, scheduledItemPercentage]);

  return (
    <WorkItemMetric
      percentage={parseInt(Number(scopedPercentage).toFixed())}
      state='SCOPED'
      detail={<><ScopeRisk scopedPercentage={scopedPercentage}/><br/>{estimatedItemPercentage.toFixed()}% E, {scheduledItemPercentage.toFixed()}% S, {committedItemPercentage.toFixed()}% C</>}
      />
  );
}