import WorkItem from '../model/WorkItem';
import WorkItemType from '../model/WorkItemType';
import WorkItemCards from './WorkItemCards';

import { useCallback, useContext, useState } from 'react';
import BacklogContext from './BacklogContext';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import WorkItemIcon from './WorkItemIcon';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CardsIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import WorkItemViewList from './WorkItemViewList';
import { Box, Dialog, IconButton } from '@mui/material';
import WorkItemState from '../model/WorkItemState';
import CardIcon from '@mui/icons-material/CreditCard';
import ReportIcon from '@mui/icons-material/ListAlt';
import WorkItemCard from './WorkItemCard';
import ReportCard from './ReportCard';
import BurndownIcon from '@mui/icons-material/BarChart';
import BurndownCard from './BurndownCard';
import useEffectAsync from './hooks/UseEffectAsync';
import { sleep } from '../Util';
// import useQuery from './hooks/UseQuery';
// import Backlog from '../model/Backlog';

type WorkItemViewProps = {
  id: number,
  defaultFocusId?: number,
  defaultExpanded?: boolean,
  defaultChildViewType?: string
}

// function getChildContainingItem(backlog: Backlog, parentItem: WorkItem, searchId: number) {
//   let child = parentItem.findById(searchId); // Find child
//   if (!child || child.id === parentItem.id) return undefined; // If not owned by the parent treee then stop
//   let parent = backlog.findParent(searchId); // Find the direct parent to the child
//   if (parent && parent.id === parentItem.id) return child; // If direct parent contains the child no need to go further
//   while (parent) { // Parent item is not the direct parent so find the child
//     if (parent.parentId === parentItem.id) return parent;
//     if (!parent.parentId) return undefined;
//     parent = backlog.findParent(parent.parentId);
//   }
//   return undefined;
// }

export default function WorkItemView({ id, defaultFocusId, defaultExpanded, defaultChildViewType }: WorkItemViewProps) {
  // const query = useQuery();
  const { /*backlog,*/ loadWorkItem } = useContext(BacklogContext);
  const [ workItem, setWorkItem ] = useState<WorkItem>(new WorkItem(id, "Loading ...", WorkItemType.Backlog));

  const getDefaultFocusId = useCallback(() => {
    // let defaultWorkItem = query.get("workitem");
    // if (!defaultWorkItem) return defaultFocusId;

    // let child = getChildContainingItem(backlog, workItem, parseInt(defaultWorkItem));
    // if (child) return child.id;

    return defaultFocusId;
  }, [defaultFocusId]);
  // }, [backlog, workItem, query, defaultFocusId]);

  const [ expanded, setExpanded ] = useState(defaultExpanded || getDefaultFocusId() ? true : false);
  const [ focusId, setFocusId ] = useState(getDefaultFocusId());
  const [ childViewType, setChildViewType ] = useState<string>(defaultChildViewType ?? "list");
  const [ focusViewType, setFocusViewType ] = useState<string>("list");
  const [ openCard, setOpenCard ] = useState(false);
  const [ openReport, setOpenReport ] = useState(false);
  const [ openBurndown, setOpenBurndown ] = useState(false);

  const handleChildViewTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newChildViewType: string,
  ) => {
    event.stopPropagation();
    if (focusId !== undefined) {
      setFocusId(undefined);
    }
    if (newChildViewType) {
      setFocusViewType(newChildViewType);
      setChildViewType(newChildViewType);
    }
  };

  useEffectAsync(async signal => {
    await sleep(); // Let the item render before waiting on load for a more responsive UI
    let workItem = await loadWorkItem(id, 0, signal, true);
    if(signal.aborted) return;

    if (workItem) {
      setWorkItem(workItem);
    } else {
      console.error("Failed to load: " + id);
    }
  }, [id, loadWorkItem]);

  const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
    setExpanded(isExpanded);
  }, []);

  const handleFocus = useCallback((event: React.SyntheticEvent<Element, Event>, id: number) => {
    setFocusId(id); // Child ID to focus
    setFocusViewType(childViewType); // Child will be focused using the same view type as this parent
    setChildViewType("list"); // This parent needs to be in list view now so the child can be show with its expanded children
    setExpanded(true); // This parent must be expanded to show its children
  }, [childViewType]);

  const handleEdit = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    let link = workItem.serviceDocument?._links?.['html']?.['href']; // Some items are missing this link
    if (!link) link = "https://microsoft.visualstudio.com/OS/_workitems/edit/" + workItem.id;
    window.open(link);
  }, [workItem]);

  const handleOpenBurndown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenBurndown(true);
  }, []);

  const handleCloseBurndown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenBurndown(false);
  }, []);

  const handleOpenReport = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenReport(true);
  }, []);

  const handleCloseReport = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenReport(false);
  }, []);

  const handleOpenCard = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpenCard(true);
  }, []);

  const handleCloseCard = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenCard(false);
  }, []);

  const WorkItemViewDetials = useCallback(() => {
    if (!expanded) return <></>

    // Show either the card view or the work item list view

    // Card view
    if (childViewType === "card") {
      return <Box marginTop={0}><WorkItemCards ids={workItem.childIds} onFocus={handleFocus} /></Box>
    }

    // List view
    return <WorkItemViewList ids={workItem.childIds}
      defaultExpanded={focusId !== undefined ? [focusId] : []}
      defaultChildViewType={focusId !== undefined ? new Map([[focusId, focusViewType]]) : undefined} />
  }, [expanded, childViewType, focusId, focusViewType, handleFocus, workItem.childIds]);

  // Don't show cut items
  if (workItem.state === WorkItemState.Cut) {
    return <></>
  }

  return (
    <>
      <Accordion onChange={handleChange} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header" sx={{flex: 1}}
        >
          <Box display='flex' flex={1}>
            <Box width='100%' marginRight={2}>
              <Box display='flex' flex={1} alignItems='center'>
                <WorkItemIcon type={workItem.type} sx={{ marginRight: '10pt' }} />
                <Typography color='text.secondary' flexShrink={0}>
                  <strong>{WorkItemType.toString(workItem.type)} {id}</strong> ({workItem.serviceDocument?.fields?.["System.AssignedTo"]?.["displayName"] ?? "Not Assigned"}) - {workItem.iteration} - {WorkItemState.toString(workItem.state)}
                </Typography>
                <IconButton onClick={handleEdit} sx={{transform: 'scale(0.75)'}}>
                  <EditIcon color="action" />
                </IconButton>
              </Box>
              <Box display='flex' flex={1}>
                <Typography marginRight={1} flexShrink={1} fontWeight="500">
                  {workItem.title}
                </Typography>
              </Box>
            </Box>
            <Box marginLeft='auto' marginRight={2} display='flex' alignItems='center'>
              {
                expanded && <ToggleButtonGroup
                  value={childViewType}
                  exclusive
                  onChange={handleChildViewTypeChange}
                  aria-label="child view type"
                  sx={{ marginLeft: 'auto', marginRight: 2 }}
                >
                  <ToggleButton value="card" aria-label="card">
                    <CardsIcon />
                  </ToggleButton>
                  <ToggleButton value="list" aria-label="list">
                    <ListIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              }
              <IconButton onClick={handleOpenCard}>
                <CardIcon />
              </IconButton>
              <IconButton onClick={handleOpenReport}>
                <ReportIcon />
              </IconButton>
              <IconButton onClick={handleOpenBurndown}>
                <BurndownIcon />
              </IconButton>
              <Box width='50px' textAlign={'center'}>
                <Chip label={workItem.childIds.length} />
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <WorkItemViewDetials />
        </AccordionDetails>
      </Accordion>
      <Dialog onClose={handleCloseCard} open={openCard} scroll='body'>
        <WorkItemCard id={workItem.id} onFocus={(e, id) => {setOpenCard(false); handleFocus(e, id);  }} />
      </Dialog>
      <Dialog onClose={handleCloseReport} open={openReport} scroll='body' maxWidth={false} fullWidth={false}>
        <ReportCard id={workItem.id} />
      </Dialog>
      <Dialog onClose={handleCloseBurndown} open={openBurndown} scroll='body' maxWidth={false} fullWidth={false}>
        <BurndownCard id={workItem.id} />
      </Dialog>
    </>
  );
}