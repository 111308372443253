import { useCallback, useEffect, useState } from "react";
import WorkItem from "../../model/WorkItem";
import LowlightIcon from '@mui/icons-material/HighlightOff';
import TextMetric from "./TextMetric";
import { InsightsLowlight } from "../../model/InsightsDocument";
import Typography from "@mui/material/Typography";

type LowlightsStatusMetricProps = {
  workItem: WorkItem;
  reportIndex: number;
  mode?: 'card' | 'text';
};

export default function LowlightsStatusMetric({ workItem, reportIndex, mode = 'card' }: LowlightsStatusMetricProps) {
  const [lowlights, setLowlights] = useState<InsightsLowlight[]>([]);
  const [lowlightCount, setLowlightCount] = useState(0);

  useEffect(() => {
    const doc = workItem.insightsDocument;
    if (!doc?.reports || !doc.reports.length) return;
    let report = doc.reports[reportIndex];
    if (report.lowlights) {
      setLowlights(report.lowlights);
      setLowlightCount(report.lowlights.length);
    } else {
      setLowlights([]);
      setLowlightCount(0);
    }
  }, [workItem, reportIndex]);

  const LowlightsText = useCallback(() => {
    return(
      <ul>
      {lowlights?.map(({ item }, index) =>
        <li key={index}>{item}</li>
      )}
      </ul>
    )
  }, [lowlights]);

  if (!lowlightCount)
    return (<></>);

  if (mode === 'text') {
    return (
      <>
        <Typography variant="h6">Lowlights</Typography>
        <LowlightsText />
      </>
    );
  }

  return (
    <TextMetric workItemType={workItem.type} color="red" summary={
      <>
        <LowlightIcon sx={{ marginRight: 1 }} />
        {lowlightCount} {lowlightCount === 1 ? "Lowlight" : "Lowlights"}
      </>
    }>
      <LowlightsText />
    </TextMetric>
  );
}