import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import WorkItem from "../model/WorkItem";
import BacklogContext from "./BacklogContext";

const options = {
  // timeline: {
  //   groupByRowLabel: true,
  //   // width: 900
  //   // height: 1000
  //   // height: "800px"
  // },
  gantt: {
    trackHeight: 60,
    labelMaxWidth: 400
  }
};

// const columns = [
//   { type: "string", id: "Name" },
//   { type: "string", id: "Phase" },
//   { type: "date", id: "Start" },
//   { type: "date", id: "End" },
// ];

const columns = [
  { type: "string", label: "Task ID" },
  { type: "string", label: "Task Name" },
  { type: "string", label: "Resource" },
  { type: "date", label: "Start Date" },
  { type: "date", label: "End Date" },
  { type: "number", label: "Duration" },
  { type: "number", label: "Percent Complete" },
  { type: "string", label: "Dependencies" },
];

type ReportScheduleProps = {
  workItem: WorkItem;
  depth?: number;
};

function wordWrap(str: string, maxWidth: number) {
  let newLineStr = "\n"; let res = '';
  while (str.length > maxWidth) {
      let found = false;
      // Inserts new line at first whitespace of the line
      for (let i = maxWidth - 1; i >= 0; i--) {
          if (testWhitespace(str.charAt(i))) {
              res = res + [str.slice(0, i), newLineStr].join('');
              str = str.slice(i + 1);
              found = true;
              break;
          }
      }
      // Inserts new line at maxWidth position, the word is too long to wrap
      if (!found) {
          res += [str.slice(0, maxWidth), newLineStr].join('');
          str = str.slice(maxWidth);
      }

  }

  return res + str;
}

function testWhitespace(x: string) {
  var white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
};

export default function ReportSchedule({workItem, depth:requestedDepth = 2}: ReportScheduleProps) {
  const [data, setData] = useState<any[]>([]);
  const { backlog } = useContext(BacklogContext);

  useEffect(() => {
    if (!workItem) return;

    let rows: any[] = [];
    let stack: {depth: number, item: WorkItem}[] = [{depth: 0, item: workItem}];

    while(stack.length) {
      const entry = stack.pop();
      if (entry === undefined) break;
      const {depth, item} = entry;
      let checkDateItem:WorkItem | undefined = item;
      let iterationDates = checkDateItem.getScheduledIterationsAsDateRange();
      while (!iterationDates && checkDateItem && checkDateItem.parentId) {
        checkDateItem = backlog.findById(checkDateItem.parentId);
        iterationDates = checkDateItem?.getScheduledIterationsAsDateRange();
      }
      if (!iterationDates) {
        iterationDates = { start: new Date(), end: new Date() };
      }
      rows.push([
        item.id.toString(),
        Array(depth).join('  ') + wordWrap(item.title,40),
        null,// depth === 0 ? "All" : "Child",
        iterationDates.start,
        iterationDates.end,
        null,
        item.getCompletedMetric().completedPercentage,
        null // item.childIds.join(",")
        ]
      );
      // Sort in reverse order so largest number is listed first.
      // That way the stack will pop them off smallest first.
      let sortedChildren = [...item.children].sort((a, b) => {
        if (a.iterationAsNumber < b.iterationAsNumber) {
          return 1;
        } else if(a.iterationAsNumber > b.iterationAsNumber) {
          return -1;
        } else {
          return 0;
        }
      });
      if (depth < requestedDepth-1) {
        stack.push(...(sortedChildren.map(child => {return {depth: depth + 1, item: child}})));
      }
    }
    let data = [columns, ...rows];
    setData(data);
  }, [workItem, requestedDepth, backlog]);

  if (!data.length) {
    return (
      <Box>
        <Typography>Loading ...<br/><br/></Typography>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Chart
      chartType="Gantt"
      data={data}
      options={options}
      height={60 * data.length}
    />
  );
}
