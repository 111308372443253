// import React, { useEffect } from 'react';
// import logo from './logo.svg';

// import personalAccessToken from '../local/token';
//     setAuthHandler(NodeApi.getPersonalAccessTokenHandler(personalAccessToken, true));

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { grey } from '@mui/material/colors';

import './App.css';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import FilterIcon from '@mui/icons-material/FilterList'

import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest, msalConfig } from "./authConfig";
// import { useIsAuthenticated } from "@azure/msal-react";
import * as NodeApi from "azure-devops-node-api";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Backlog from './components/Backlog';
import { useCallback, useState } from 'react';
import { createTheme } from '@mui/material/styles';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Paper from '@mui/material/Paper';
import ThemeProvider from '@mui/system/ThemeProvider';
import useEffectAsync from './components/hooks/UseEffectAsync';
import DevOpsProvider from './components/DevOpsProvider';
import { IRequestHandler } from 'azure-devops-node-api/interfaces/common/VsoBaseInterfaces';

function BacklogTitle() {
  const { team } = useParams();

  return <> - {team}</>
}

function App() {
  const [msal] = useState(new PublicClientApplication(msalConfig));
  const [anchorEl, setAnchorEl] = useState(null);
  const [authHandler, setAuthHandler] = useState<IRequestHandler|undefined>(undefined);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilter = (event: any) => {

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffectAsync(async signal => {
    const authResult = await msal.handleRedirectPromise();

    if (!authResult?.account) {
      setAuthHandler(undefined);
      try {
        msal.loginRedirect(loginRequest);
      } catch(e) {
        console.error(e);
      }
    } else {
      console.log("Authenticated user: " + authResult.account.tenantId + "/" + authResult.account.username);
      console.log("Access token: " + authResult.accessToken);
      setAuthHandler(NodeApi.getBearerHandler(authResult.accessToken, true));
    }
  }, []);

  const BacklogList = useCallback(() => {
    // const theme = createTheme({
    //   typography: {
    //     fontSize: 12,
    //   },
    // });
    const theme = createTheme();

    theme.typography.h2 = {
      fontSize: '1.4rem',
    };
    theme.typography.h3 = {
      fontSize: '1.2rem',
    };
    return (
      <ThemeProvider theme={theme}>
        <Typography variant="h2">
          Sample Backlogs
        </Typography>
        <Typography>
          Choose from an existing team backlog or update the address bar URL to the following format, replacing the `:` properties as needed: `/:project/backlogs/:team`.
        </Typography>
        <ul style={{ marginRight: 32 }}>
          <li><Link to="/OS/backlogs/CSI - Commercial Servicing and Intelligence">CSI - Commercial Servicing and Intelligence</Link></li>
          <ul>
            <li><Link to="/OS/backlogs/Deployment Service Platform">Deployment Service Platform</Link></li>
            <li><Link to="/OS/backlogs/Reporting and Service Maturity">Reporting and Service Maturity</Link></li>
            <li><Link to="/OS/backlogs/On Prem and Rollout">On Prem and Rollout</Link></li>
          </ul>
        </ul>
        <Typography variant="h2">
          Process Overview
        </Typography>
        <Typography variant="h3">
          Work Item Types
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Timing</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </ThemeProvider>
    );
  }, []);

  const HomePage = useCallback(() => {
    return (
      <Paper style={{ marginTop: 32 }}>
        <BacklogList />
      </Paper>
    );
  }, [BacklogList]);

  // TODO: Also revise routes once OAuth is supported. https://v5.reactrouter.com/web/example/auth-workflow

  return (
    <Router>
      <MsalProvider instance={msal}>
        <AuthenticatedTemplate>
          <DevOpsProvider authHandler={authHandler}>
            <div className="App" style={{ backgroundColor: grey[800] }}>
              <AppBar position="sticky">
                <Toolbar >
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" component="div" flexGrow={1}>
                    DevOps Insights
                    <Routes>
                      <Route path="/:project/backlogs/:team" element={<BacklogTitle />} />
                    </Routes>
                  </Typography>
                  <IconButton
                    size="large"
                    aria-label="filter"
                    onClick={handleFilter}
                    color="inherit"
                  >
                    <FilterIcon />
                  </IconButton>
                  <div>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem key="logout" onClick={handleClose}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </Toolbar>
              </AppBar>
              <Routes>
                <Route path="/:project/backlogs/:team" element={<Backlog />} />
                <Route path="/" element={<HomePage />} />
              </Routes>
            </div>
          </DevOpsProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p>You are not signed in! Please sign in.</p>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </Router>
  );
}

export default App;
