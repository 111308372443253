import WorkItemView from './WorkItemView';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import BacklogContext from './BacklogContext';

type WorkItemViewProps = {
  ids: number[];
  defaultExpanded?: number[];
  defaultChildViewType?: Map<number, string>;
};

export default function WorkItemViewList({ids, defaultExpanded = [], defaultChildViewType = new Map<number, string>()}: WorkItemViewProps) {
  const { backlog } = useContext(BacklogContext);
  const [sortedIds, setSortedIds] = useState(ids);

  useEffect(() => {
    let sortedIds = ids.slice(0);
    sortedIds.sort((firstId, secondId) => {
      let firstItem = backlog.findFirst(item => item.id === firstId);
      let secondItem = backlog.findFirst(item => item.id === secondId);
      if (!firstItem && !secondItem)
        return 0;

      if (!firstItem || !firstItem.iteration)
        return 1;

      if (!secondItem || !secondItem.iteration)
        return -1;

      if (firstItem.iteration === secondItem.iteration)
        return firstItem.id < secondItem.id ? -1 : 1;

      return firstItem.iterationAsNumber < secondItem.iterationAsNumber ? -1 : 1;
    });
    setSortedIds(sortedIds);
  }, [ids, backlog]);

  return (
    <Box sx={{ minWidth: 500, maxWidth: 1000 }}>
      {
        sortedIds.length ?
          sortedIds.map(id => <WorkItemView key={id} id={id} defaultExpanded={defaultExpanded.indexOf(id) !== -1} defaultChildViewType={defaultChildViewType.get(id) ?? "list"} />)
          : <Typography align='center'>No items</Typography>
      }
    </Box>
  );
}